export const TYPE = {
    NAME: 'NAME'
};

export const FORM_ACTIONS = {
    CLEAR_SELECTED_TRF: 'CLEAR_SELECTED_TRF',
    GET_OFFLINE_FORM_COUNT: 'GET_OFFLINE_FORM_COUNT',
    FORM_ACTIONS_SUBMITNEWFORMDATA_PENDING: 'FORM_ACTIONS_SUBMITNEWFORMDATA_PENDING',
    FORM_ACTIONS_SUBMITNEWFORMDATA_SUCCESS: 'FORM_ACTIONS_SUBMITNEWFORMDATA_SUCCESS',
    FORM_ACTIONS_SUBMITNEWFORMDATA_FAILURE: 'FORM_ACTIONS_SUBMITNEWFORMDATA_FAILURE',
    FORM_ACTIONS_SUBMITNEWFORMDATA_OFFLINE: 'FORM_ACTIONS_SUBMITNEWFORMDATA_OFFLINE',
    FORM_ACTIONS_SUBMIT_ASSIGNED_TRFID_PENDING: 'FORM_ACTIONS_SUBMIT_ASSIGNED_TRFID_PENDING',
    FORM_ACTIONS_SUBMIT_ASSIGNED_TRFID_SUCCESS: 'FORM_ACTIONS_SUBMIT_ASSIGNED_TRFID_SUCCESS',
    FORM_ACTIONS_SUBMIT_ASSIGNED_TRFID_FAILURE: 'FORM_ACTIONS_SUBMIT_ASSIGNED_TRFID_FAILURE',
    FORM_ACTIONS_SUBMIT_ASSIGNED_TRFID_OFFLINE: 'FORM_ACTIONS_SUBMIT_ASSIGNED_TRFID_OFFLINE',
    FORM_ACTIONS_SUBMITEXISTINGFORMDATA_PENDING: 'FORM_ACTIONS_SUBMITEXISTINGFORMDATA_PENDING',
    FORM_ACTIONS_SUBMITEXISTINGFORMDATA_SUCCESS: 'FORM_ACTIONS_SUBMITEXISTINGFORMDATA_SUCCESS',
    FORM_ACTIONS_SUBMITEXISTINGFORMDATA_FAILURE: 'FORM_ACTIONS_SUBMITEXISTINGFORMDATA_FAILURE',
    FORM_ACTIONS_SUBMITEXISTINGFORMDATA_OFFLINE: 'FORM_ACTIONS_SUBMITEXISTINGFORMDATA_OFFLINE',
    GET_IMAGES_SUCCESS: 'GET_IMAGES_SUCCESS',
    FORM_ACTIONS_SUBMITNEWIMAGEDATA_PENDING: 'FORM_ACTIONS_SUBMITNEWIMAGEDATA_PENDING',
    FORM_ACTIONS_SUBMITNEWIMAGEMDATA_SUCCESS: 'FORM_ACTIONS_SUBMITNEWIMAGEMDATA_SUCCESS',
    FORM_ACTIONS_SUBMITNEWIMAGEDATA_OFFLINE: 'FORM_ACTIONS_SUBMITNEWIMAGEDATA_OFFLINE',
    FORM_ACTIONS_SUBMITNEWIMAGEDATA_FAILURE: 'FORM_ACTIONS_SUBMITNEWIMAGEDATA_FAILURE',
    FORM_ACTIONS_SUBMITEXISTINGIMAGEDATA_PENDING: 'FORM_ACTIONS_SUBMITEXISTINGIMAGEDATA_PENDING',
    FORM_ACTIONS_SUBMITEXISTINGIMAGEDATA_SUCCESS: 'FORM_ACTIONS_SUBMITEXISTINGIMAGEDATA_SUCCESS',
    FORM_ACTIONS_SUBMITEXISTINGIMAGEDATA_OFFLINE: 'FORM_ACTIONS_SUBMITEXISTINGIMAGEDATA_OFFLINE',
    FORM_ACTIONS_SUBMITEXISTINGIMAGEDATA_FAILURE: 'FORM_ACTIONS_SUBMITEXISTINGIMAGEDATA_FAILURE',
    GET_ITF_FORM_PENDING: 'GET_ITF_FORM_PENDING',
    GET_ITF_FORM_SUCCESS: 'GET_ITF_FORM_SUCCESS',
    GET_ITF_FORM_FAILURE: 'GET_ITF_FORM_FAILURE',
    GET_ITF_FORM_OFFLINE_SUCCESS: 'GET_ITF_FORM_OFFLINE_SUCCESS',
    SET_FORMTAG_SELECTED: 'SET_FORMTAG_SELECTED',
    GET_DPSRECORD_PENDING: 'GET_DPSRECORD_PENDING',
    GET_DPSRECORD_SUCCESS: 'GET_DPSRECORD_SUCCESS',
    RESET_FORM_DATA: 'RESET_FORM_DATA',
    SET_SELECTED_TRF: 'SET_SELECTED_TRF',
    DELETE_IMAGE: 'DELETE_IMAGE',
    CAPTURE_IMAGE: 'CAPTURE_IMAGE',
    FORM_ACTIONS_DELETEIMAGES_PENDING: 'FORM_ACTIONS_DELETEIMAGES_PENDING',
    FORM_ACTIONS_DELETEIMAGES_SUCCESS: 'FORM_ACTIONS_DELETEIMAGES_SUCCESS',
    FORM_ACTIONS_DELETEIMAGES_OFFLINE: 'FORM_ACTIONS_DELETEIMAGES_OFFLINE',
    FORM_ACTIONS_DELETEIMAGES_FAILURE: 'FORM_ACTIONS_DELETEIMAGES_FAILURE',
    GETIMAGES_PENDING: 'GETIMAGES_PENDING',
    GETIMAGES_SUCCESS: 'GETIMAGES_SUCCESS',
    GETIMAGES_FAILURE: 'GETIMAGES_FAILURE',
    FORM_ACTIONS_SUBMITTRFIMAGE_SUCCESS: 'FORM_ACTIONS_SUBMITTRFIMAGE_SUCCESS',
    FORM_ACTIONS_SUBMITTRFIMAGE_OFFLINE: 'FORM_ACTIONS_SUBMITTRFIMAGE_OFFLINE',
    FORM_ACTIONS_SUBMITTRFIMAGE_FAILURE: 'FORM_ACTIONS_SUBMITTRFIMAGE_FAILURE',
    GET_TRF_FORM_IMAGE: 'GET_TRF_FORM_IMAGE',
    SET_P81_FORMDATA: 'SET_P81_FORMDATA',
    SET_SRA_FORMDATA: 'SET_SRA_FORMDATA',
    CLEAR_SRA_FORMDATA: 'CLEAR_SRA_FORMDATA',
    UPDATE_IMAGE_NOTE_PENDING: 'UPDATE_IMAGE_NOTE_PENDING',
    UPDATE_IMAGE_NOTE_SUCCESS: 'UPDATE_IMAGE_NOTE_SUCCESS',
    UPDATE_IMAGE_NOTE_FAILURE: 'UPDATE_IMAGE_NOTE_FAILURE',
    UPDATE_IMAGE_NOTE_OFFLINE_SUCCESS: 'UPDATE_IMAGE_NOTE_OFFLINE_SUCCESS',
    UPDATE_IMAGE_NOTE_OFFLINE_FAILURE: 'UPDATE_IMAGE_NOTE_OFFLINE_FAILURE',
    SET_IS_EDIT_IMAGE: 'SET_IS_EDIT_IMAGE',
    UPDATE_FORMDATE_OBJECT: 'UPDATE_FORMDATE_OBJECT',
    EDIT_IMAGE_NOTE: 'EDIT_IMAGE_NOTE',
    CLEAR_EDIT_IMAGE_LIST: 'CLEAR_EDIT_IMAGE_LIST',
    UPDATE_ASFOUND_TESTPOINTRANGE: 'UPDATE_ASFOUND_TESTPOINTRANGE',
    UPDATE_ASLEFT_TESTPOINTRANGE: 'UPDATE_ASLEFT_TESTPOINTRANGE',
    UPDATE_TESTPOINTRANGE: 'UPDATE_TESTPOINTRANGE',
    UPDATE_FLOWALARM_TESTPOINTRANGE: 'UPDATE_FLOWALARM_TESTPOINTRANGE',
    UPDATE_SPINNER_VISIBLE: 'UPDATE_SPINNER_VISIBLE',
    FORM_ACTIONS_GET_MULTI_TRF_DETAILS_DATA_SUCCESS: 'HOME_ACTIONS_GET_MULTI_TRF_DETAILS_DATA_SUCCESS',
    FORM_ACTIONS_SAVE_DRAFT_ONLINE_SUCCESS: 'FORM_ACTIONS_SAVE_DRAFT_ONLINE_SUCCESS',
    FORM_ACTIONS_SAVE_DRAFT_OFFLINE_SUCCESS: 'FORM_ACTIONS_SAVE_DRAFT_OFFLINE_SUCCESS',
    FORM_ACTIONS_UPDATE_DRAFT_ONLINE_SUCCESS: 'FORM_ACTIONS_UPDATE_DRAFT_ONLINE_SUCCESS',
    FORM_ACTIONS_UPDATE_DRAFT_OFFLINE_SUCCESS: 'FORM_ACTIONS_UPDATE_DRAFT_OFFLINE_SUCCESS',
    FORM_ACTIONS_SAVE_DRAFT_ONLINE_FAILURE: 'FORM_ACTIONS_SAVE_DRAFT_ONLINE_FAILURE',
    FORM_ACTIONS_SAVE_DRAFT_OFFLINE_FAILURE: 'FORM_ACTIONS_SAVE_DRAFT_OFFLINE_FAILURE',
    FORM_ACTIONS_UPDATE_DRAFT_ONLINE_FAILURE: 'FORM_ACTIONS_UPDATE_DRAFT_ONLINE_FAILURE',
    FORM_ACTIONS_UPDATE_DRAFT_OFFLINE_FAILURE: 'FORM_ACTIONS_UPDATE_DRAFT_OFFLINE_FAILURE',
};

export const FormNames = {
    M31: 'M3-1',
    M61: 'M6-1',
    M63: 'M6-3',
    M64: 'M6-4',
    M66: 'M6-6',
    M65: 'M6-5',
    M67: 'M6-7',
    M69: 'M6-9',
    M613: 'M6-13',
    M68: 'M6-8',
    M610: 'M6-10',
    M611: 'M6-11',
    M612: 'M6-12',
    P71: 'P7-1',
    P72: 'P7-2',
    P73: 'P7-3',
    P730: 'P7-30',
    P74: 'P7-4',
    P75: 'P7-5',
    P76: 'P7-6',
    C11: 'C1-1',
    C14: 'C1-4',
    P77: 'P7-7',
    P78: 'P7-8',
    P79: 'P7-9',
    P710: 'P7-10',
    P712: 'P7-12',
    P714: 'P7-14',
    P718: 'P7-18',
    P716: 'P7-16',
    P717: 'P7-17',
    P719: 'P7-19',
    P715: 'P7-15',
    P725: 'P7-25',
    P726: 'P7-26',
    P729: 'P7-29',
    P728: 'P7-28',
    P81: 'P8-1',
    C12: 'C1-2',
    P64: 'P6-4',
    P642: 'P6-4.2',
    P65: 'P6-5',
    P652: 'P6-5.2',
    P62: 'P6-2',
    P61: 'P6-1',
    P67: 'P6-7',
    P63: 'P6-3',
    P617: 'P6-17',
    C13: 'C1-3',
    C132: 'C1-3.2',
    P632: 'P6-3.2',
    P811: 'P8-1.1',
    P861: 'P8-6.1',
    P871: 'P8-7.1',
    P881: 'P8-8.1',
    P612: 'P6-1.2',
    P622: 'P6-2.2',
    P66: 'P6-6',
    P69: 'P6-9',
    P68: 'P6-8',
    P610: 'P6-10',
    P6012: 'P6-12',
    P613: 'P6-13',
    P821: 'P8-2.1',
    P851: 'P8-5.1',
    P831: 'P8-3.1',
    P841: 'P8-4.1',
    M614: 'M6-14',
    M615: 'M6-15',
    M616: 'M6-16',
    M71: 'M7-1',
    P723: 'P7-23',
    P8101: 'P8-10.1',
    P611: 'P6-11',
    P6142: 'P6-14.2',
    P614: 'P6-14',
    P6232: 'P6-23.2',
    P724: 'P7-24',
    P727: 'P7-27',
    P741: 'P7-4.1',
    P713: 'P7-13',
    P616: 'P6-16',
    P732: 'P7-32',
    P733: 'P7-33',
    P731: 'P7-31',
    P615: 'P6-15',
    P620: 'P6-20',
    P891: 'P8-9.1',
    P8111: 'P8-11.1',
    P720: 'P7-20',
    P721: 'P7-21',
    P8121: 'P8-12.1',
    P711: 'P7-11',
    P8141: 'P8-14.1',
    P8171: 'P8-17.1',
    P618: 'P6-18',
    P6022: 'P6-22',
    P722: 'P7-22',
    P8131: 'P8-13.1',
    S71: 'S7-1',
    S72: 'S7-2',
    S73: 'S7-3',
    S74: 'S7-4',
    M42: 'M4-2'
};
